$player-size: 85vmin;
$units: $player-size / 100;

$ring-size: 4 * $units;
$ring-grow-amount: 1.5 * $units;

$disc0-size: 78 * $units;
$disc1-size: 66 * $units;
$disc2-size: 54 * $units;
$disc3-size: 42 * $units;

.is-spinning {
    animation-name: spin;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.party-player {
    width: 100 * $units;
    height: 100 * $units;

    margin: auto;

    position: relative;
}

.party-player_bottom {
    position: absolute;
    bottom: 1 * $units;
    width: 100%;
    z-index: 1;
}

.party-player_bottom-line {
    height: 0.5 * $units;
    background: #888;
    margin: 1.5 * $units 3 * $units;
    width: 80%;
}

.party-player_circles {
    width: 84 * $units;
    height: 84 * $units;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.party-player_outer-disc {
    width: 84 * $units;
    height: 84 * $units;
    background: #111;
    border-radius: 100%;
    flex-shrink: 0;
    position: absolute;
    z-index: 2;
}

.party-player_hole {
    width: 5 * $units;
    height: 5 * $units;
    background: #2b2827;
    border-radius: 100%;
    flex-shrink: 0;
    position: absolute;
    z-index: 7;
}

.party-player_label {
    background: #aaa;
    border-radius: 100%;
    width: 30 * $units;
    height: 30 * $units;
    flex-shrink: 0;
    position: absolute;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.party-player_disc {
    border-radius: 100%;
    position: absolute;
    transition: height 0.5s, width 0.5s, opacity 0.5s, border 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    box-sizing: border-box;
    border-style: solid;
}

.party-player_disc:hover {
    opacity: 0.85;
}

.party-player_disc.is-active {
    opacity: 1;
}

.party-player_disc {
    border-width: $ring-size;
}

.party-player_disc.is-active,
.party-player_disc:hover {
    border-width: $ring-size + $ring-grow-amount;
}

.party-player_disc0 {
    z-index: 3;

    width: $disc0-size;
    height: $disc0-size;

    &:hover,
    &.is-active {
        width: $disc0-size + $ring-grow-amount;
        height: $disc0-size + $ring-grow-amount;
    }
}

.party-player_disc1 {
    z-index: 4;

    width: $disc1-size;
    height: $disc1-size;

    &:hover,
    &.is-active {
        width: $disc1-size + $ring-grow-amount;
        height: $disc1-size + $ring-grow-amount;
    }
}

.party-player_disc2 {
    z-index: 5;

    width: $disc2-size;
    height: $disc2-size;

    &:hover,
    &.is-active {
        width: $disc2-size + $ring-grow-amount;
        height: $disc2-size + $ring-grow-amount;
    }
}

.party-player_disc3 {
    z-index: 6;

    width: $disc3-size;
    height: $disc3-size;

    &:hover,
    &.is-active {
        width: $disc3-size + $ring-grow-amount;
        height: $disc3-size + $ring-grow-amount;
    }
}

.party-player_stylus {
    height: 10 * $units;
    width: 10 * $units;
    position: absolute;
    right: 5 * $units;
    top: -3 * $units;
    transition: transform 0.5s;
    z-index: 500;
}

.party-player_stylus-pivot {
    border-radius: 100%;
    width: 10 * $units;
    height: 10 * $units;
    background: #968c81;
    position: absolute;
    right: 0vmin;
    top: 0vmin;
    border: 1 * $units solid #111;
    box-sizing: border-box;
    z-index: 505;
}

.party-player_stylus-arm {
    background: #4f4c49;
    width: 4 * $units;
    height: 50 * $units;
    position: absolute;
    right: 3 * $units;
    top: 5 * $units;
    border-radius: 1.5 * $units;
    z-index: 504;
}

.party-player_stylus-bend {
    transform: rotate(45deg);
    background: #4f4c49;
    width: 4 * $units;
    height: 15 * $units;
    position: absolute;
    right: 7 * $units;
    top: 50 * $units;
    border-radius: 2 * $units;
    z-index: 504;
}

.party-player_stylus-needle {
    transform: rotate(45deg);
    width: 9 * $units;
    height: 13 * $units;
    background: #968c81;
    position: absolute;
    top: 57 * $units;
    right: 10.5 * $units;
    z-index: 505;
    border: 0.5 * $units solid #4f4c49;
    box-sizing: border-box;
}

.party-player_stop {
    width: 9 * $units;
    height: 9 * $units;
    left: 3.5 * $units;
    bottom: 11.5 * $units;
    position: absolute;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.party-player_stop-button {
    position: absolute;
    width: 8 * $units;
    height: 8 * $units;
    background: #968c81;
    border: 0.65 * $units solid #4f4c49;
    border-style: outset;
    transition: border 0.05s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}

.party-player_stop-button:active {
    border-width: 0.9 * $units;
}

.party-player_stop-icon {
    width: 2 * $units;
    height: 2 * $units;
    background: #312f2d;
    border-radius: 0.2 * $units;
    transition: width 0.05s, height 0.05s;
}

.party-player_stop-button:active .party-player_stop-icon {
    width: 1.85714285714 * $units; // Literally no clue what this number is
    height: 1.85714285714 * $units;
}

.party-player_flip-disc1 {
    width: 9 * $units;
    height: 9 * $units;
    right: 26.5 * $units;
    bottom: 11.5 * $units;
    position: absolute;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.party-player_flip-disc2 {
    width: 9 * $units;
    height: 9 * $units;
    right: 16.5 * $units;
    bottom: 11.5 * $units;
    position: absolute;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.party-player_flip-button {
    position: absolute;
    width: 8 * $units;
    height: 8 * $units;
    background: #968c81;
    border: 0.65 * $units solid #4f4c49;
    border-style: outset;
    transition: border 0.05s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}

.party-player_flip-button:active {
    border-width: 0.9 * $units;
}

.party-player_flip-button .icon {
    font-size: 4 * $units;
    font-weight: 900;
    color: #312f2d;
    transition: font-size 0.05s;
}

.party-player_flip-button:active .icon {
    font-size: 3.3 * $units;
}

.party-player_label-text {
    color: #222;
    font-weight: 700;
    position: absolute;
    text-align: center;
}

.party-player_label-title {
    font-size: 2.2 * $units;
    top: 4.25 * $units;
    width: 22 * $units;
}

.party-player_label-artist {
    font-size: 2 * $units;
    bottom: 3.25 * $units;
    width: 20 * $units;
}

.party-player_volume {
    position: absolute;
    width: 7 * $units;
    height: 20 * $units;
    right: 2 * $units;
    bottom: 2 * $units;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5000;
}

.VolumeBox-icon {
    // color: transparent;
    // text-shadow: 0 0 0 #968c81;
    font-size: 2 * $units;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 15vmin;
    height: 15vmin;
    margin: 1vmin 0;
    background: transparent;
    transform: rotate(-90deg);
}

input[type='range']:focus {
    outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
    width: 15vmin;
    height: 1.25vmin;
    cursor: pointer;
    background: #968c81;
    border-radius: 0px;
    transition: background 0.1s;
    box-shadow: none;
    border: none;
}

input[type='range']::-webkit-slider-thumb {
    border: 0.25vmin solid #111;
    height: 3.25vmin;
    width: 1.75vmin;
    border-radius: 0px;
    background: #4f4c49;
    cursor: pointer;
    box-shadow: none;
    margin-top: -1vmin;
    -webkit-appearance: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
    background: #77736d;
}

input[type='range']::-moz-range-track {
    width: 15vmin;
    height: 1.25vmin;
    cursor: pointer;
    background: #968c81;
    border-radius: 0px;
    transition: background 0.1s;
    box-shadow: none;
    border: none;
}

input[type='range']::-moz-range-thumb {
    border: 0.25vmin solid #111;
    height: 3.25vmin;
    width: 1.75vmin;
    border-radius: 0px;
    background: #4f4c49;
    cursor: pointer;
    box-shadow: none;
}

input[type='range']::-ms-track {
    width: 15vmin;
    height: 1.25vmin;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type='range']::-ms-fill-lower {
    background: #4f4c49;
    border: none;
    border-radius: 0px;
    box-shadow: none;
}

input[type='range']::-ms-fill-upper {
    background: #4f4c49;
    border: none;
    border-radius: 0px;
    box-shadow: none;
}

input[type='range']::-ms-thumb {
    border: 0.25vmin solid #111;
    height: 3.25vmin;
    width: 1.75vmin;
    border-radius: 0px;
    background: #4f4c49;
    cursor: pointer;
    box-shadow: none;
}

input[type='range']:focus::-ms-fill-lower {
    background: #77736d;
}

input[type='range']:focus::-ms-fill-upper {
    background: #77736d;
}
