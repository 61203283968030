.page-header {
    top: 0;

    z-index: 500;
}

@media screen and (min-width: 769px), print {
    .page-header {
        position: -webkit-sticky;
        position: sticky;
    }
}

html[data-theme='dark'] .page-header {
    background: #000000;
}

html[data-theme='light'] .page-header {
    background: #ffffff;
}

.settings-button-container {
    position: relative;
}

.settings-button-tooltip {
    position: absolute;
    top: 4rem;
    right: 0;
}

.settings-button-tooltip-arrow {
    $size: 0.5rem;

    position: absolute;
    top: -$size;
    right: 2.5rem;

    width: 0;
    height: 0;

    border-left: $size solid transparent;
    border-right: $size solid transparent;

    border-bottom: $size solid #ffdd57;
}

.settings-button-tooltip-text {
    padding: 1.5rem !important;

    font-size: 1.5rem !important;
}
